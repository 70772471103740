@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: BerlinSans;
  src: url('/public/fonts/BerlinSansFB-Bold.ttf');
}

@font-face {
  font-family: PublicSans;
  src: url('/public/fonts/PublicSans-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: PublicSans;
  src: url('/public/fonts/PublicSans-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: PublicSans;
  src: url('/public/fonts/PublicSans-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: PublicSans;
  src: url('/public/fonts/PublicSans-Regular.ttf');
}

@font-face {
  font-family: PublicSans;
  src: url('/public/fonts/PublicSans-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: PublicSans;
  src: url('/public/fonts/PublicSans-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: PublicSans;
  src: url('/public/fonts/PublicSans-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Amble;
  src: url('/public/fonts/Amble-Regular.ttf');
}

@font-face {
  font-family: Amble;
  src: url('/public/fonts/Amble-Italic.ttf');
  font-style: italic;
}

@font-face {
  font-family: Amble;
  src: url('/public/fonts/Amble-Bold.ttf');
  font-weight: 700;
}



body {
  margin: 0;
  font-family: 'Amble', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-height: 100vh;
  background-image: url('../public/images/bg.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-color: gray;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}